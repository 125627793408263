import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { domain } from "../../Components/config";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useNavigate } from "react-router-dom";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LoginIcon from "@mui/icons-material/Login";
import jwtDecode from "jwt-decode";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles({
  card: {
    margin: "20px 0",
    backgroundColor: "#f5f5f5",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  dataGrid: {
    "& .MuiDataGrid-root": {
      backgroundColor: "#fafafa",
    },
  },
});

const ProfileMain = () => {
  const { login, checkAuthStatus } = useAuth();
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [isUserBan, setIsUserBan] = useState(false);
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobile, setMobile] = useState(null);
  const [password,setPassword] = useState(null)

  const handleEditClick = () => {
    navigate("/edit-user-bank-details"); // Replace with your actual edit page route
  };

  const lockUserAPI = async (mobile) => {
    console.log("MOBILE --->",mobile)
    axios.delete(`${domain}/deleteuser`, {
      params: { mobile },
      withCredentials: true,
    })
    .then(() => {
      setIsUserBan(true);
      alert("This user is Banned successfully.");
    })
    .catch((error) => {
      console.error("Error locking user:", error);
    });
    
  };

  const unlockUserAPI = async (mobile) => {
    axios
      .put(
        `${domain}/unlockuser`,
        { mobile: mobile },
        { withCredentials: true }
      )
      .then(() => {
        setIsUserBan(false);
        alert("This user is Unbanned successfully.");
      })
      .catch((error) => {
        console.error("Error unlocking user:", error);
      });
  };

  const handleLockUser = async (mobile) => {
    await lockUserAPI(mobile); // Assuming this is the API call to lock the user
    setIsUserBan(true); // Update state to reflect the change
  };

  const handleUnlockUser = async (mobile) => {
    await unlockUserAPI(mobile); // Assuming this is the API call to unlock the user
    setIsUserBan(false); // Update state to reflect the change
  };

  const handleAdminLoginAsUser = (userMobile, userPassword) => {
    axios.post(`${domain}/logout`, {}, { withCredentials: true })
      .then(() => {
        return axios.post(`${domain}/login`, {
          mobile: userMobile,
          password: userPassword,
        }, { withCredentials: true });
      })
      .then((response) => {
        // Assuming response.data contains { token, admin, superAdmin }
        const { token, admin, superAdmin } = response.data;
        login(token, admin, superAdmin);
        
        // Recheck auth status after login
        checkAuthStatus();
        window.location.href = '/home';
      })
      .catch((error) => {
        console.error("Error in admin login as user process:", error);
      });
  };

  const onLoginAsUserClick = () => {
    console.log("Function Called",mobile,password);

    handleAdminLoginAsUser(mobile,password);
  };

  useEffect(() => {
    axios
      .get(`${domain}/user-profile/${userId}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setData(response.data);
        console.log(response.data.user.mobile);
        setMobile(response.data.user.mobile)
        setPassword(response.data.user.plainPassword)
        setIsUserBan(response.data?.user.locked);
      })
      .catch((error) => console.error("Error:", error));
  }, [userId]);

  console.log("userdata is --->", data);

  const columns = [
    { field: "_id", headerName: "ID" },
    { field: "userId", headerName: "User ID" },
    { field: "selectedItem", headerName: "Selected Item" },
    { field: "betAmount", headerName: "Bet Amount" },
    { field: "multiplier", headerName: "Multiplier" },
    { field: "totalBet", headerName: "Total Bet" },
    { field: "tax", headerName: "Tax" },
    { field: "fee", headerName: "Fee" },
    { field: "selectedTimer", headerName: "Selected Timer" },
    { field: "periodId", headerName: "Period ID" },
    { field: "timestamp", headerName: "Timestamp" },
    { field: "result", headerName: "Result" },
    { field: "status", headerName: "Status" },
    { field: "winLoss", headerName: "Win/Loss" },
  ];
  const depositColumns = [
    { field: "_id", headerName: "ID" },
    { field: "userId", headerName: "User ID" },
    { field: "uid", headerName: "UID" },
    { field: "depositAmount", headerName: "Deposit Amount" },
    { field: "depositDate", headerName: "Deposit Date" },
    { field: "depositStatus", headerName: "Deposit Status" },
    { field: "depositId", headerName: "Deposit ID" },
    { field: "depositMethod", headerName: "Deposit Method" },
  ];

  const withdrawColumns = [
    { field: "_id", headerName: "ID" },
    { field: "status", headerName: "Status" },
    { field: "balance", headerName: "Balance" },
    { field: "withdrawMethod", headerName: "Withdraw Method" },
    { field: "userId", headerName: "User ID" },
    { field: "createdAt", headerName: "Created At" },
    { field: "updatedAt", headerName: "Updated At" },
  ];
  return (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              color: "#ffffff",
              display: "flex",
              borderRadius: "4px",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flexGrow: 1, p: 2, bgcolor: "#2c397e" }}>
              Username <br />
              <span style={{ fontSize: "1.3rem" }}>{data?.user.username}</span>
              {/* Larger font for dynamic data */}
            </Box>
            <Box
              sx={{
                bgcolor: "#3f51b5",
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AccountCircleIcon />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              color: "#ffffff",
              display: "flex",
              borderRadius: "4px",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flexGrow: 1, p: 2, bgcolor: "#259182" }}>
              Wallet Amount <br />
              <span style={{ fontSize: "1.3rem" }}>₹{data?.walletAmount}</span>
              {/* Larger font for dynamic data */}
            </Box>
            <Box
              sx={{
                bgcolor: "#35d0ba",
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CurrencyRupeeIcon />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              color: "#ffffff",
              display: "flex",
              borderRadius: "4px",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flexGrow: 1, p: 2, bgcolor: "#482980" }}>
              UID <br />
              <span style={{ fontSize: "1.3rem" }}>{data?.user.uid}</span>
              {/* Larger font for dynamic data */}
            </Box>
            <Box
              sx={{
                bgcolor: "#673ab7",
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FingerprintIcon />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            sx={{
              color: "#ffffff",
              display: "flex",
              borderRadius: "4px",
              overflow: "hidden",
              fontWeight: "bold",
            }}
          >
            <Box sx={{ flexGrow: 1, p: 2, bgcolor: "#b26624" }}>
              Mobile Number <br />
              <span style={{ fontSize: "1.3rem" }}>{data?.user.mobile}</span>
              {/* Larger font for dynamic data */}
            </Box>
            <Box
              sx={{
                bgcolor: "#ff9234",
                p: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PhoneAndroidIcon />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* <Grid item xs={12} container spacing={2} sx={{ ml: "-16px" }}>
        <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            // onClick={onLoginAsUserClick}
            sx={{
              width: "100%",
              bgcolor: "#eb2222",
              textTransform: "none",
              "&:hover": {
                bgcolor: "#c81212",
                boxShadow: "0 8px 15px 0 rgba(234, 84, 85, 0.45)",
              },
            }}
          >
            <LoginIcon sx={{ mr: 1 }} />
            Login as User
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              bgcolor: "#28c76f",
              textTransform: "none",
              "&:hover": {
                bgcolor: "#1f9d57",
                boxShadow: "0 5px 10px 0 rgba(40, 199, 111, 0.35)",
              },
            }}
            // onClick={() => navigate(`/wallet-update/${data?.user.uid}`)}
          >
            <AccountBalanceWalletIcon sx={{ mr: 1 }} />
            Wallet Update
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              bgcolor: "#4634ff",
              textTransform: "none",
              "&:hover": {
                bgcolor: "#1801ff",
                boxShadow: "0 5px 10px 0 rgba(115, 103, 240, 0.35)",
              },
            }}
          >
            <ChecklistIcon sx={{ mr: 1 }} />
            Logins
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ textAlign: "center" }}>
          {!data?.user.locked || !isUserBan ? (
            <Button
              variant="contained"
              onClick={() => handleLockUser(data?.user.mobile)}
              sx={{
                width: "100%",
                bgcolor: "#fd9e44",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#ff9f43",
                  boxShadow: "0 8px 15px 0 rgba(255, 159, 67, 0.45)",
                },
              }}
            >
              <DoDisturbAltIcon sx={{ mr: 1 }} />
              Ban User
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleUnlockUser(data?.user.mobile)}
              sx={{
                width: "100%",
                bgcolor: "#fd9e44",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#ff9f43",
                  boxShadow: "0 8px 15px 0 rgba(255, 159, 67, 0.45)",
                },
              }}
            >
              <DoDisturbAltIcon sx={{ mr: 1 }} />
              Unbanned User
            </Button>
          )}
        </Grid>
      </Grid> */}
      <Grid item xs={isMobile ? 12 : 3}>
        <Card sx={{ mt: 2 }}>
          <CardHeader
            title="Bank Details"
            titleTypographyProps={{ fontWeight: "bold" }}
            // action={
            //   <IconButton onClick={handleEditClick}>
            //     <DriveFileRenameOutlineIcon />
            //   </IconButton>
            // }
          />
          <CardContent sx={{ overflow: "auto", pt: 0 }}>
            {data?.bankDetails.map((detail, index) => (
              <Table sx={{ border: "none" }}>
                <TableBody>
                  <TableRow
                    sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      Name
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>{detail.name}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      Account No
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {detail.accountNo}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      IFSC Code
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {detail.ifscCode}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {detail.mobile}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ border: "none" }}
                    >
                      Bank Name
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {detail.bankName}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }}>
          <CardHeader
            title="Direct Subordinates"
            titleTypographyProps={{ fontWeight: "bold" }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Table sx={{ border: "none" }}>
              <TableBody>
                <TableRow
                  sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    No of Register
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.directSubordinates.noOfRegister}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    Deposit Number
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.directSubordinates.depositNumber}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    Deposit Amount
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.directSubordinates.depositAmount}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    First Deposit
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.directSubordinates.firstDeposit}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }}>
          <CardHeader
            title="Team Subordinates"
            titleTypographyProps={{ fontWeight: "bold" }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Table sx={{ border: "none" }}>
              <TableBody>
                <TableRow
                  sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    No of Register
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.teamSubordinates.noOfRegister}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    Deposit Number
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.teamSubordinates.depositNumber}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#ffffff", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    Deposit Amount
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.teamSubordinates.depositAmount}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ backgroundColor: "#F8F8FB", borderBottom: "none" }}
                >
                  <TableCell component="th" scope="row" sx={{ border: "none" }}>
                    First Deposit
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    {data?.teamSubordinates.firstDeposit}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Card sx={{ mt: 2 }}>
          <CardHeader
            title="Commission From Levels"
            titleTypographyProps={{ fontWeight: "bold" }}
          />
          <CardContent sx={{ pt: 0 }}>
            {data?.commission &&
              Object.entries(data.commission).map(
                ([level, commission], index) => (
                  <p key={index}>{`${level}: ${commission}`}</p>
                )
              )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={isMobile ? 12 : 9}>
        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "#ffffff",
            minHeight: "200px",
            boxShadow: 2,
            borderRadius: 1,
          }}
        >
          <h2 style={{ margin: 0 }}>Bet History</h2>
          {data?.bets && (
            <DataGrid
              rows={data.bets}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              getRowId={(row) => row._id}
              sx={{
                border: "none",
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  //  borderBottom: 'none',
                },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#F8F8FB", // Light color for odd rows
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#ffffff", // White color for even rows
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  width: "6px", // Set scrollbar width
                  height: "6px", // Set scrollbar height (for horizontal scrollbars)
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc", // Thumb color
                  borderRadius: "8px", // Rounded corners for thumb
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#555", // Darker on hover
                  },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                  backgroundColor: "#f1f1f1", // Track color
                },
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "#ffffff",
            minHeight: "200px",
            boxShadow: 2,
            borderRadius: 1,
          }}
        >
          <h2 style={{ margin: 0 }}>Deposit History</h2>
          {data?.depositHistory && (
            <DataGrid
              rows={data.depositHistory}
              columns={depositColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              getRowId={(row) => row._id}
              sx={{
                border: "none",
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  //  borderBottom: 'none',
                },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#F8F8FB", // Light color for odd rows
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#ffffff", // White color for even rows
                },
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "#ffffff",
            minHeight: "200px",
            boxShadow: 2,
            borderRadius: 1,
          }}
        >
          <h2 style={{ margin: 0 }}>Withdraw History</h2>
          {data?.withdraws && (
            <DataGrid
              rows={data.withdraws}
              columns={withdrawColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              getRowId={(row) => row._id}
              sx={{
                border: "none",
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  //  borderBottom: 'none',
                },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#F8F8FB", // Light color for odd rows
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#ffffff", // White color for even rows
                },
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProfileMain;
